<template>
  <div>
    <currency-type :objectType="objectType"></currency-type>
  </div>
</template>
<script>
import CurrencyType from "@/components/FileType/CurrencyType";
export default {
  data(){
    return{
      objectType: {
        Type: 3, // 任务档案
        FlowName: true,
        FileName: "任务档案"
      },
    }
  },
  components:{
    CurrencyType
  },
};
</script>

<!--<template>-->
<!--  <div class="TackAechive-page">-->
<!--    <div class="content_left">-->
<!--      <treedata @projectId-child="projectIdClick"></treedata>-->
<!--    </div>-->
<!--    <div class="TackAechive-content">-->
<!--      <div class="TackAechive-header-upload">-->
<!--        <div class="TackAechive-header-top">-->
<!--          <span>文件名:</span>-->
<!--          <el-input placeholder="请输入" v-model="FileName"></el-input>-->
<!--          <span>上传开始日期:</span>-->
<!--          <el-date-picker-->
<!--            v-model="date1"-->
<!--            type="date"-->
<!--            placeholder="选择日期"-->
<!--            format="yyyy 年 MM 月 dd 日"-->
<!--            value-format="yyyy-MM-dd">-->
<!--          </el-date-picker>-->
<!--          <span>所属阶段:</span>-->
<!--          <el-input v-model="FlowName" placeholder="请输入阶段"></el-input>-->
<!--          <span>所属项目:</span>-->
<!--          <el-input placeholder="请输入" v-model="TopicName" />-->
<!--        </div>-->
<!--        <div class="query-content">-->
<!--          <div>-->
<!--            <span>上传人员:</span>-->
<!--            <el-input placeholder="请输入" v-model="UserName"></el-input>-->
<!--            <span>上传结束日期:</span>-->
<!--            <el-date-picker-->
<!--              v-model="date2"-->
<!--              type="date"-->
<!--              placeholder="选择日期"-->
<!--              format="yyyy 年 MM 月 dd 日"-->
<!--              value-format="yyyy-MM-dd">-->
<!--            </el-date-picker>-->
<!--          </div>-->
<!--          <el-button type="primary" icon="el-icon-search" style="right: 0" @click="queryTask">查询</el-button>-->
<!--        </div>-->
<!--        <el-divider></el-divider>-->
<!--        <div  class="query-content">-->
<!--          <el-button type="primary" icon="el-icon-top" @click="add">上传文件</el-button>-->
<!--          <div>-->
<!--            <el-button type="primary" icon="el-icon-bottom" @click="dowFiles">打包下载</el-button>-->
<!--            <el-button type="primary" icon="el-icon-delete" @click="delFiles">删除</el-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="content" style="height: 520px">-->
<!--        <el-table-->
<!--          :data="tableData"-->
<!--          style="width: 100%"-->
<!--          max-height="520"-->
<!--          fit-->
<!--          :header-cell-style="{-->
<!--              color: '#333333',-->
<!--              backgroundColor: '#FBFBFD',-->
<!--            }"-->
<!--          row-key="id"-->
<!--          border-->
<!--          ref="multipleTable"-->
<!--          @selection-change="handleSelectionChange"-->
<!--        >-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            type="selection"-->
<!--            width="50">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            label="序号"-->
<!--            type="index"-->
<!--            align="center"-->
<!--            width="62">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            label="文件名称"-->
<!--            prop="FileName"-->
<!--            align="center"-->
<!--            min-width="150">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            label="上传人员"-->
<!--            prop="UserName"-->
<!--            align="center"-->
<!--            min-width="120">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            label="上传时间"-->
<!--            prop="CreateDate"-->
<!--            align="center"-->
<!--            min-width="150">-->
<!--            <template slot-scope="scope">-->
<!--              <span>{{scope.row.CreateDate.substring(0,10)}}</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            label="备注"-->
<!--            prop="Remarks"-->
<!--            align="center"-->
<!--            width="120">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            label="所属阶段"-->
<!--            prop="FlowName"-->
<!--            align="center"-->
<!--            width="150">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            label="所属单位"-->
<!--            prop="HospitalName"-->
<!--            align="center"-->
<!--            min-width="150">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            prop="TopicName"-->
<!--            label="所属项目"-->
<!--            align="center"-->
<!--            min-width="150">-->
<!--          </el-table-column>-->
<!--          <el-table-column label="操作" align="center" width="210">-->
<!--            <template slot-scope="scope">-->
<!--              <el-link-->
<!--                style="font-size:16px;color: #3978E7;margin-right: 10px"-->
<!--                type="text"-->
<!--                :underline="false"-->
<!--                @click="pre(scope.row.Id)"-->
<!--              ><img src="../../../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;vertical-align: -3px">查看</el-link>-->
<!--&lt;!&ndash;              <el-link&ndash;&gt;-->
<!--&lt;!&ndash;                style="font-size:18px;color: #3978E7;margin-right: 10px"&ndash;&gt;-->
<!--&lt;!&ndash;                type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                :underline="false"&ndash;&gt;-->
<!--&lt;!&ndash;                @click="edit(scope.row)"&ndash;&gt;-->
<!--&lt;!&ndash;              ><i class="el-icon-edit-outline"></i>编辑</el-link>&ndash;&gt;-->
<!--              <el-link-->
<!--                style="font-size:16px;color: #3978E7;margin-right: 10px"-->
<!--                type="text"-->
<!--                :underline="false"-->
<!--                @click="deletefile(scope.row.Id)"-->
<!--              ><i class="el-icon-delete"></i>删除</el-link>-->
<!--              <el-link-->
<!--                style="font-size:16px;color: #3978E7;"-->
<!--                type="text"-->
<!--                :underline="false"-->
<!--                @click="downloadfile(scope.row.Id)"-->
<!--              ><i class="el-icon-bottom"></i>下载</el-link>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--        </el-table>-->
<!--      </div>-->
<!--      <div class="block">-->
<!--        <el-pagination-->
<!--          background-->
<!--          @current-change="handleCurrentChange"-->
<!--          :current-page="currentPage"-->
<!--          :page-size="eachpage"-->
<!--          layout="total,prev, pager, next, jumper"-->
<!--          :total="PageCount">-->
<!--        </el-pagination>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="TackAechive-upload">-->
<!--      <el-dialog-->
<!--        :visible.sync="dialogVisible"-->
<!--      >-->
<!--        <div slot="title" class="header-title">-->
<!--          <span class="title-name"></span>-->
<!--          <span class="title-age">上传文件</span>-->
<!--        </div>-->
<!--        <el-form ref="form" label-width="120px">-->
<!--          <el-form-item label="备注:">-->
<!--            <el-input type="textarea" v-model="Remarks" :autosize="{ minRows: 4, maxRows: 6}"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="成果上传:">-->
<!--            <el-upload-->
<!--              class="upload-demo"-->
<!--              ref="newupload"-->
<!--              accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc"-->
<!--              multiple-->
<!--              action="#"-->
<!--              :file-list="fileList"-->
<!--              :auto-upload="false"-->
<!--              :on-change = "change"-->
<!--              :on-remove="handleRemove"-->
<!--              :before-upload="beforeUpload"-->
<!--            >-->
<!--              <el-button size="medium" type="primary" icon="el-icon-top">上传文件</el-button>-->
<!--&lt;!&ndash;              <div slot="tip" class="el-upload__tip">&ndash;&gt;-->
<!--&lt;!&ndash;                只能上传jpg/png文件，且文件大小不超过500kb&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </el-upload>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--        <div class="btn">-->
<!--          <button  @click="dialogVisible = false">取消</button>-->
<!--          <button  @click="determine">确定</button>-->
<!--        </div>-->
<!--      </el-dialog>-->
<!--    </div>-->
<!--    <div class="Task-dialog">-->
<!--      <el-dialog-->
<!--        top="35vh"-->
<!--        :visible.sync="delTaskDialog"-->
<!--        width="510px"-->
<!--      >-->
<!--        <div slot="title" class="header-title">-->
<!--          <span class="title-name"></span>-->
<!--          <span class="title-age">提示</span>-->
<!--        </div>-->
<!--        <div style="text-align: center">-->
<!--          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>-->
<!--          <span style="font-size: 18px;color: #666">此操作将永久删除该任务文件，是否继续？</span>-->
<!--        </div>-->
<!--        <div class="btn">-->
<!--          <button  @click="delTaskDialog = false">取消</button>-->
<!--          <button @click="submitDel">确定</button>-->
<!--        </div>-->
<!--      </el-dialog>-->
<!--    </div>-->
<!--    <div class="Task-dialog">-->
<!--      <el-dialog-->
<!--        top="35vh"-->
<!--        :visible.sync="delFilesDialog"-->
<!--        width="510px"-->
<!--      >-->
<!--        <div slot="title" class="header-title">-->
<!--          <span class="title-name"></span>-->
<!--          <span class="title-age">提示</span>-->
<!--        </div>-->
<!--        <div style="text-align: center">-->
<!--          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>-->
<!--          <span style="font-size: 18px;color: #666">此操作将永久删除该选择的任务文件，是否继续？</span>-->
<!--        </div>-->
<!--        <div class="btn">-->
<!--          <button  @click="delFilesDialog = false">取消</button>-->
<!--          <button @click="submitDelLists">确定</button>-->
<!--        </div>-->
<!--      </el-dialog>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->
<!--<script>-->
<!--import reseacrh from "../../../api/research.js"-->
<!--import {mapState} from "vuex";-->
<!--import treedata from "@/components/treedata";-->
<!--import {downloadFileId,downLoadXls} from "@/utils/downloadUrl";-->
<!--export default {-->
<!--  data(){-->
<!--    return{-->
<!--      DataGuidList: [], // 打包文件的DataGuid数组-->
<!--      date1: "",// 上传开始日期-->
<!--      date2: "", // 上传结束日期-->
<!--      UserName: "", // 用户名-->
<!--      FlowName: "",//阶段名称-->
<!--      FileName: "", // 文件名-->
<!--      TopicName: "", //项目名称-->
<!--      delFilesDialog: false, // 批量删除任务对象弹框-->
<!--      delTaskDialog: false, // 删除单个任务对象弹框-->
<!--      delId: null, // 删除任务Id-->
<!--      achivesForm: {-->
<!--        date1: "",// 上传开始日期-->
<!--        date2: "", // 上传结束日期-->
<!--        fileName: "", // 上传文件名-->
<!--        name: "", // 上传人员-->
<!--        stage: "", // 所属阶段-->
<!--        project: "",// 所属项目-->
<!--      },-->
<!--      selectItem: "",-->
<!--      options: [{-->
<!--        value: '1',-->
<!--        label: '单位1'-->
<!--      }, {-->
<!--        value: '2',-->
<!--        label: '单位2'-->
<!--      }, {-->
<!--        value: '3',-->
<!--        label: '单位3'-->
<!--      }, {-->
<!--        value: '4',-->
<!--        label: '单位4'-->
<!--      }],-->
<!--      fileType: "", // 上传类型-->
<!--      fileName: "", // 文件名称-->
<!--      Remarks:"", // 备注说明-->
<!--      fileList:[], // 上传文件列表-->
<!--      dialogVisible:false, //上传文件弹框-->
<!--      multipleSelection: [],//多选框数据-->
<!--      tableData: [],-->
<!--      ppt:"ppt",-->
<!--      imglist:[],-->
<!--      num:-1,-->
<!--      value:"",-->
<!--      currentPage:1,-->
<!--      PageCount:0,-->
<!--      eachpage:10-->
<!--    }-->
<!--  },-->
<!--  computed: mapState(["projectId"]),-->
<!--  components:{-->
<!--    treedata-->
<!--  },-->
<!--  created(){-->
<!--    this.filelist()-->
<!--  },-->
<!--  methods:{-->
<!--    projectIdClick(data) { //树型结构传递过来的课题id-->
<!--      this.$store.commit('SaveId',data)-->
<!--      this.currentPage = 1;-->
<!--      this.eachpage = 13;-->
<!--      this.PageCount = 0-->
<!--      this.filelist()-->
<!--    },-->
<!--    changeOption() { // 选择之后查询-->
<!--      this.filelist()-->
<!--    },-->
<!--    handleRemove(file, fileList) {-->
<!--      this.fileList = fileList;-->
<!--      // console.log(this.fileList)-->
<!--    },-->
<!--    change(file,fileList) {-->
<!--      this.fileList = fileList-->
<!--      // console.log(this.fileList)-->
<!--    },-->
<!--    determine(){ //确定文件上传-->
<!--      if (this.fileList.length == 0) {-->
<!--        this.$message.error('请上传文件')-->
<!--      }-->
<!--      this.$refs.newupload.submit();-->
<!--    },-->
<!--    beforeUpload(file){ // 选择的文件上传-->
<!--      let objUpload = {-->
<!--        // Id: this.$store.state.TopicId,-->
<!--        Type: 3,-->
<!--        Id: this.projectId,-->
<!--        Remarks: this.Remarks,-->
<!--      };-->
<!--      // console.log(file);-->
<!--      let fd = new FormData();-->
<!--      fd.append("file", file);-->
<!--      fd.append("data", JSON.stringify(objUpload));-->
<!--      reseacrh.TopicUploadFile(fd).then((res) => {-->
<!--        if (res.data.Status == 1) {-->
<!--          this.dialogVisible = false;-->
<!--          this.Remarks = "";-->
<!--          this.filelist()-->
<!--          this.$message.success(res.data.Message);-->
<!--        }else{-->
<!--          this.$message.error(res.data.Message)-->
<!--        }-->
<!--        // console.log(res);-->
<!--      });-->
<!--      return true;-->
<!--    },-->
<!--    add() { // 上传文件-->
<!--      this.fileList = []-->
<!--      this.dialogVisible=true-->
<!--      this.Remarks = "";-->
<!--    },-->
<!--    edit() { //编辑-->

<!--    },-->
<!--    handleSelectionChange(val) { //多选-->
<!--      this.multipleSelection = val;-->
<!--      // console.log(this.multipleSelection,'多选')-->
<!--    },-->
<!--    handleCurrentChange(val){-->
<!--      this.currentPage=val-->
<!--      this.filelist()-->
<!--      // console.log(`当前页: ${val}`);-->
<!--    },-->
<!--    pre(id){-->
<!--      reseacrh.preview(id).then(res=>{-->
<!--        console.log(res);-->
<!--        if(res.data.Status==1){-->
<!--          if(/\.(pdf|PDF|png|PNG|jpg|JPG)$/.test(res.data.ExtraData.Url)){-->
<!--            window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);-->
<!--          }else{-->
<!--            this.$message.warning("暂时只支持pdf文件预览")-->
<!--            // window.open("https://view.officeapps.live.com/op/view.aspx?src=" + window.global_config.BASE_URL2+res.data.ExtraData.Url);-->
<!--          }-->
<!--        }-->
<!--      })-->

<!--    },-->
<!--    queryTask() {-->
<!--      this.filelist()-->
<!--    },-->
<!--    filelist(){-->
<!--      var time1-->
<!--      var time2-->
<!--      if (this.date1 == "" || this.date2) {-->
<!--        time1= "";-->
<!--        time2 = ""-->
<!--      } else {-->
<!--        time1 = this.date1 + ' '  +'00:00:00'-->
<!--        time2 = this.date2 + ' ' +'23:59:59'-->
<!--      }-->
<!--      var fileobj={-->
<!--        TopicId:this.projectId,-->
<!--        Type: 3,-->
<!--        PageIndex:this.currentPage-1,-->
<!--        PageSize:this.eachpage,-->
<!--        FileName:this.FileName,-->
<!--        FlowName: this.FlowName,-->
<!--        TopicName: this.TopicName,-->
<!--        UserName: this.UserName,-->
<!--        StartDate: time1,-->
<!--        EndDate: time2-->
<!--      }-->
<!--      reseacrh.GetPagedTopicDataType(fileobj).then(res=>{-->
<!--        if(res.data.Status==1){-->
<!--          // this.imglist=res.data.Entity-->
<!--          this.tableData = res.data.Entity-->
<!--          this.PageCount=res.data.TotalRowsCount-->
<!--          // console.log(res);-->
<!--        }else{-->
<!--          this.$message.warning(res.data.Message)-->
<!--        }-->
<!--      })-->
<!--    },-->
<!--    submitDelLists() { // 提交批量删除任务-->
<!--      this.multipleSelection.forEach( (item,index)=>{-->
<!--        reseacrh.alldelet({Id:item.Id}).then((res)=>{-->
<!--          if (this.multipleSelection.length-1 == index) {-->
<!--            this.$message.success(res.data.Message)-->
<!--            this.filelist()-->
<!--            this.delFilesDialog = false-->
<!--          }-->
<!--        })-->
<!--      })-->
<!--      // this.filelist()-->
<!--      this.delFilesDialog = false-->
<!--    },-->
<!--    delFiles() { // 批量删除文件-->
<!--      if (this.multipleSelection.length == 0) {-->
<!--        this.$message.warning('请选择需要删除的文件')-->
<!--        return false-->
<!--      }-->
<!--      this.delFilesDialog = true-->
<!--    },-->
<!--    submitDel() { // 确定删除单个成果文件-->
<!--      reseacrh.alldelet({Id:this.delId}).then(res=>{-->
<!--        if(res.data.Status==1){-->
<!--          this.$message.success(res.data.Message)-->
<!--          this.filelist()-->
<!--          this.delTaskDialog = false-->
<!--        }else{-->
<!--          this.$message.error(res.data.Message)-->
<!--        }-->
<!--      })-->
<!--    },-->
<!--    deletefile(id){//删除文件-->
<!--      this.delTaskDialog = true-->
<!--      this.delId = id-->
<!--    },-->
<!--    dowFiles() {-->
<!--      console.log(this.multipleSelection,'zhi')-->
<!--      this.DataGuidList = []-->
<!--      if (this.multipleSelection.length === 0) {-->
<!--        this.$message.warning('请选择需要下载的文件')-->
<!--        return false-->
<!--      }-->
<!--      // this.multipleSelection.forEach(item => {-->
<!--      //   this.downloadfile(item.Id)-->
<!--      // })-->
<!--      this.multipleSelection.forEach(item => {-->
<!--        this.DataGuidList.push(item.PDataGuid)-->
<!--      })-->
<!--      console.log(this.DataGuidList,'任务')-->
<!--      reseacrh.DownloadFileZip({DataGuids: this.DataGuidList}).then(res => {-->
<!--        console.log(res,'通用打包下载')-->
<!--        if (res.status == 200) {-->
<!--          let data = res.data;-->
<!--          // let filename = decodeURIComponent(res.headers.filename)-->
<!--          // downLoadXls(data,filename)-->
<!--          downLoadXls(data,"任务档案.zip")-->
<!--        } else {-->
<!--          // this.$message.error(res.data.Message)-->
<!--          this.$message.error('打包失败')-->
<!--        }-->
<!--      })-->
<!--    },-->
<!--    downloadfile(id){-->
<!--      downloadFileId(id)-->
<!--    },-->
<!--  }-->
<!--}-->
<!--</script>-->
<!--<style lang="scss" scoped>-->
<!--@import "../../../styles/mixin.scss";-->
<!--.TackAechive-page {-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--  display: flex;-->
<!--  overflow: hidden;-->
<!--  .content_left {-->
<!--    width:260px;-->
<!--    height: 94%;-->
<!--    margin-top: 2%;-->
<!--    border-radius: 10px;-->
<!--    background-color: white;-->
<!--    //box-sizing: border-box;-->
<!--    //background: red;-->
<!--  }-->
<!--  .TackAechive-content{-->
<!--    //width: 1340px;-->
<!--    width: 83%;-->
<!--    height: 94%;-->
<!--    margin-left: 24px;-->
<!--    margin-top: 2%;-->
<!--    margin-right: 24px;-->
<!--    background: #FFFFFF;-->
<!--    padding: 1% 1% 0 1%;-->
<!--    border-radius: 10px;-->
<!--    box-sizing: border-box;-->
<!--    .TackAechive-header-upload{-->
<!--      width: 100%;-->
<!--      margin-bottom: 20px;-->
<!--      .TackAechive-header-top {-->
<!--        margin-bottom: 10px;-->
<!--        margin-left: 15px;-->
<!--      }-->
<!--      span {-->
<!--        color: #69696A;-->
<!--        @include add-size($font_size_16);-->
<!--      }-->
<!--      /deep/.el-select {-->
<!--        @include add-size($font_size_16);-->
<!--        width: 180px;-->
<!--        height: 40px;-->
<!--        //margin-left: 10px;-->
<!--        margin-right: 30px;-->
<!--      }-->
<!--      /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {-->
<!--        width: 220px;-->
<!--        @include add-size($font_size_16);-->
<!--      }-->
<!--      /deep/.el-button {-->
<!--        @include add-size($font_size_16);-->
<!--      }-->
<!--      /deep/.el-input {-->
<!--        width: 180px;-->
<!--        height: 40px;-->
<!--        @include add-size($font_size_16);-->
<!--        margin-right: 20px;-->
<!--        margin-left: 10px;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->
<!--.TackAechive-upload {-->
<!--  /deep/.el-dialog {-->
<!--    width: 578px;-->
<!--    border-radius: 10px;-->
<!--    .el-dialog__header {-->
<!--      border-radius: 10px 10px 0px 0px;-->
<!--      background: #3388ff;-->
<!--      margin-bottom: 20px;-->
<!--      .header-title {-->
<!--        .title-name {-->
<!--          display: inline-block;-->
<!--          width: 10px;-->
<!--          height: 10px;-->
<!--          border: 4px solid #ffffff;-->
<!--          border-radius: 50%;-->
<!--          vertical-align: middle;-->
<!--          margin-right: 4px;-->
<!--          margin-bottom: 5px;-->
<!--        }-->
<!--        .title-age {-->
<!--          vertical-align: top;-->
<!--          display: inline-block;-->
<!--          @include add-size($font_size_16);-->
<!--          color: #ffffff;-->
<!--        }-->
<!--      }-->
<!--      .el-dialog__headerbtn .el-dialog__close {-->
<!--        color: #fff;-->
<!--      }-->
<!--    }-->
<!--    .el-form {-->
<!--      .el-form-item {-->
<!--        .el-form-item__label {-->
<!--          @include add-size($font_size_16);-->
<!--          color: #666666;-->
<!--        }-->
<!--        .el-input {-->
<!--          @include add-size($font_size_16);-->
<!--          width: 390px;-->
<!--        }-->
<!--        .el-textarea {-->
<!--          @include add-size($font_size_16);-->
<!--          width: 390px;-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--    .btn {-->
<!--      margin-top: 52px;-->
<!--      text-align: center;-->
<!--      button:nth-child(1) {-->
<!--        width: 180px;-->
<!--        background: #ffffff;-->
<!--        border: 1px solid #3388ff;-->
<!--        height: 50px;-->
<!--        border-radius: 10px;-->
<!--        font-size: 24px;-->
<!--        color: #3388ff;-->
<!--        margin-right: 20px;-->
<!--      }-->
<!--      button:nth-child(2) {-->
<!--        width: 180px;-->
<!--        border: 1px solid #3388ff;-->
<!--        background: #3388ff;-->
<!--        height: 50px;-->
<!--        border-radius: 10px;-->
<!--        font-size: 24px;-->
<!--        color: #fff;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->
<!--.Task-dialog /deep/.el-dialog {-->
<!--  border-radius: 10px;-->
<!--  .el-dialog__header {-->
<!--    border-radius: 10px 10px 0px 0px;-->
<!--    background: #3388ff;-->
<!--    margin-bottom: 20px;-->
<!--    .header-title {-->
<!--      .title-name {-->
<!--        display: inline-block;-->
<!--        width: 10px;-->
<!--        height: 10px;-->
<!--        border: 4px solid #ffffff;-->
<!--        border-radius: 50%;-->
<!--        vertical-align: middle;-->
<!--        margin-right: 4px;-->
<!--        margin-bottom: 5px;-->
<!--      }-->
<!--      .title-age {-->
<!--        vertical-align: top;-->
<!--        display: inline-block;-->
<!--        @include add-size($font_size_16);-->
<!--        color: #ffffff;-->
<!--      }-->
<!--    }-->
<!--    .el-dialog__headerbtn .el-dialog__close {-->
<!--      color: #fff;-->
<!--    }-->
<!--  }-->
<!--  .btn {-->
<!--    margin-top: 52px;-->
<!--    text-align: center;-->
<!--    button:nth-child(1) {-->
<!--      width: 180px;-->
<!--      background: #ffffff;-->
<!--      border: 1px solid #3388ff;-->
<!--      height: 50px;-->
<!--      border-radius: 10px;-->
<!--      font-size: 24px;-->
<!--      color: #3388ff;-->
<!--      margin-right: 20px;-->
<!--    }-->
<!--    button:nth-child(2) {-->
<!--      width: 180px;-->
<!--      //background: #ffffff;-->
<!--      border: 1px solid #3388ff;-->
<!--      background: #3388ff;-->
<!--      height: 50px;-->
<!--      border-radius: 10px;-->
<!--      font-size: 24px;-->
<!--      color: #fff;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--.block {-->
<!--  margin-top: 20px;-->
<!--}-->
<!--.content /deep/.el-table th {-->
<!--  @include add-size($font_size_16);-->
<!--  vertical-align: middle;-->
<!--}-->
<!--.content /deep/.el-table__row {-->
<!--  @include add-size($font_size_16);-->
<!--  vertical-align: middle;-->
<!--}-->
<!--.content {-->
<!--  ::-webkit-scrollbar {-->
<!--    width: 8px!important;-->
<!--    height: 4px;-->

<!--  }-->
<!--  ::-webkit-scrollbar-thumb {-->
<!--    //width: 20px;-->
<!--    //height: 4px;-->
<!--    background-color: #1890ff;-->
<!--    border-radius: 10px;-->
<!--  }-->
<!--}-->
<!--.query-content {-->
<!--  display: flex;-->
<!--  justify-content: space-between;-->
<!--}-->
<!--</style>-->
